import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Switch } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Profil from './pages/Profil';
import Post from './pages/Post';
import List from './pages/List';
import Download from './pages/Download';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import './Main.css';
import './Megamenu.css';
import './Style.css';
import './Responsive.css';
import './Sortcodes.css';
import './Font-Awesome.css';
import './Flaticon.css';
import './Slick.css';

const App = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch('/posts/posts.json');
      const postList = await response.json();
      /*const postList = [
        { id: 'post1', title: 'My First Blog Post' },
        { id: 'post2', title: 'My Second Blog Post' },
        { id: 'post3', title: 'My Third Blog Post' }
      ];*/
      setPosts(postList);
    };

    fetchPosts();

    
  }, []);


  return (
    <Router>
      <div className="Page">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profil" element={<Profil />} />
          <Route path="/post/:dir/:id" element={<Post />} />
          <Route path="/list/:dir" element={<List />} />
          <Route path="/Download" element={<Download />} />
        </Routes>
      </div>
     <Footer/>
    </Router>
  );
};

export default App;
