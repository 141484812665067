import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Switch } from 'react-router-dom';
import Post from './Post';

const Home = () =>{
    const [posts, setPosts] = useState([]);
    const [firstNews, setFirstNews] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await fetch('/posts/pengumuman/posts.json');
      const postList = await response.json();
      setPosts(postList);
    };
    fetchPosts();
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      const response = await fetch('/posts/berita/posts.json');
      const newsList = await response.json();
      
      if (newsList.length > 0) {
        setFirstNews(newsList[0]);
      }
    };
    fetchNews();
  }, []);
    return(
        <div>
                    <div class="banner-area">
              <div class="container">
              <div class="banner-area_item mt-30">
              <div class="banner-slider slick_slider slick-arrows-style1" data-slick='{"slidesToShow": 1, "slidesToScroll": 1, "arrows":true, "autoplay":true,"responsive": [{"breakpoint":1970,"settings":{"slidesToShow": 1}} , {"breakpoint":525,"settings":{"slidesToShow": 1}}]}'>
                <div aria-live="polite" class="slick-list draggable"><div class="slick-track banner" role="listbox"><div class="banner-slide slick-slide slick-current slick-active" style={{width: '1110px'}} data-slick-index="0" aria-hidden="false" tabindex="-1" role="option" aria-describedby="slick-slide00">
                          <img class="img-fluid lazyloaded" data-src="/assets/slider/sliderr.jpg" alt="sliderr" src="/assets/slider/sliderr.jpg" />
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
              </div>
          </div>

        <div class="site-main">
            <section class="ttm-row blog-section clearfix">
                <div class="container ">
                   
                    <div class="row">
                        <div class="col-lg-7">
                            <Routes>
                                <Route path="/post/:id" element={ <div class="row"><Post /></div>} />
                                <Route path="/" element={                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                   
                                    <div class="featured-icon-box icon-align-top-content ttm-bgcolor-darkgrey text-center style6">
                                        <a href="profil/">
                                            <div class="featured-icon">
                                                <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                    <i class="flaticon-university"></i>
                                                </div>
                                            </div>
                                            <div class="featured-content">
                                                <div class="featured-title">
                                                    <h5>PROFIL</h5>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                   
                                </div>
                                <div class="col-lg-6 col-md-6">
                                   
                                    <div class="featured-icon-box icon-align-top-content text-center ttm-bgcolor-darkgrey style6">
                                        <a href="download/">
                                        <div class="featured-icon">
                                            <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                <i class="flaticon-resume"></i>
                                            </div>
                                        </div>
                                        <div class="featured-content">
                                            <div class="featured-title">
                                                <h5>DOWNLOAD</h5>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                  
                                    <div class="featured-icon-box icon-align-top-content text-center ttm-bgcolor-darkgrey style6">
                                    <a href="list/berita">
                                        <div class="featured-icon">
                                            <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                                <i class="flaticon-file"></i>
                                            </div>
                                        </div>
                                        <div class="featured-content">
                                            <div class="featured-title">
                                                <h5>BERITA</h5>
                                            </div>
                                        </div>
                                    </a>
                                    </div>
                                </div>
                            </div>} />
                            </Routes>


                        </div>
                        <div class="col-lg-5">
                            <div class="res-991-pr-0" style={{textAlign:'left'}}>
                              
                                <div class="section-title">
                                    <div class="title-header">
                                        <h2 class="title">Pengumuman</h2>
                                    </div>
                                    <div class="heading-seperator"><span></span></div>
                                </div>
                                <div class="pt-15  pr-15 pb-15 res-991-p-0 res-991-mt-20">
                                    <div class="pt-10 pb-30 res-991-pb-15">
                                        <ul class="ttm-list ttm-list-style-icon-2">
                                        {posts.slice(0,3).map( item => (
                                            <li>
                                            <div class="ttm-list-li-content">
                                            <Link to={`/post/pengumuman/${item.id}`}> {item.title}</Link>
                                              
                                                                                 
                                            </div>
                                            <span class="post-date">
                                                <i class="fa fa-clock-o"></i>
                                                {item.date}                                                        </span>
                                        </li>
                                                       
                                                        ))}
                                            
                                        </ul>
                                      
                                    </div>
                                    <a class="ttm-btn ttm-btn-size-sm ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-dark" href="/list/pengumuman">
                                        Selengkapnya <i class="fa fa-angle-right"></i></a>
                                </div>
                                <div class="mt-40 mb-30">
                                    <span class="ttm-horizontal_sep"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </section>
            <section class="ttm-row ttm-bgcolor-grey clearfix">
                <div class="container">
                   
                    <div class="row">
                        <div class="col-lg-6" style={{textAlign:'left'}}>
                            <div class=" res-991-pr-0">
                                <div class="section-title">
                                    <div class="title-header">
                                        <h2 class="title">Berita</h2>
                                    </div>
                                    <div class="heading-seperator"><span></span></div>
                                </div>
                                {firstNews?(<div class="pt-15  pr-15 pb-15 res-991-p-0 res-991-mt-20">
                                    <div class="pt-10 pb-30 res-991-pb-15">
                                        <ul class="ttm-list ttm-list-style-icon-2">
                                                                                                <li>
                                                        <div class="ttm-list-li-content">
                                                            <Link to={`/post/berita/${firstNews.id}`}> {firstNews.title}</Link>
                                                        </div>
                                                        <span class="post-date">
                                                            <i class="fa fa-clock-o"></i> {firstNews.date}                                                        </span>
                                                    </li>
                                                                                    </ul>
                                    </div>
                                    <a class="ttm-btn ttm-btn-size-sm ttm-btn-shape-square ttm-btn-style-border ttm-btn-color-dark" href="list/berita">
                                        Selengkapnya <i class="fa fa-angle-right"></i></a>
                                </div>):("")}
                                

                            </div>
                        </div>
                        <div class="col-lg-6">

                        </div>
                    </div>
                </div>
            </section>
        </div>
        </div>
    );
};

export default Home;