import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Switch } from 'react-router-dom';
import Post from './Post';

const Download = () =>{
    const [files, setFiles] = useState([]);
    const [firstNews, setFirstNews] = useState(null);

  useEffect(() => {
    const fetchFiles = async () => {
      const response = await fetch('/download/download.json');
      const fileList = await response.json();
      setFiles(fileList);
    };

    fetchFiles();

    
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      const response = await fetch('/posts/berita/posts.json');
      const newsList = await response.json();
      
      if (newsList.length > 0) {
        setFirstNews(newsList[0]);
      }
    };
    fetchNews();
  }, []);
    return(
        <div>
            <div class="ttm-page-title-row">
            <div class="ttm-page-title-row-inner  ttm-bgcolor-darkgrey">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12" style={{textAlign:'left'}}>
                            <div class="page-title-heading">
                                <h2 class="title">Download</h2>
                            </div>
                            <div class="breadcrumb-wrapper">
                                <span><a class="pathway" href="/">Home</a></span><span><a class="pathway" href="/download"> Download</a></span>                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="site-main">
           
            <div class="ttm-row sidebar ttm-sidebar-right ttm-bgcolor-white clearfix">
                <div class="container">
                   
                    <div class="row">
                    <div class="col-xl-9 col-lg-8 content-area">
                          
                            <article class="widget widget-download" style={{textAlign:'left'}}>
                        <ul class="download">
                        {files.map( item => (
                            <li><a href={item.url} target='_blank' rel="noopener noreferrer"><i class="fa fa-file-pdf-o"></i>{item.title}</a> </li>
                        ))}
                        </ul>

</article>

                        </div>
                        <div class="col-xl-3 col-lg-4 widget-area sidebar-right ttm-col-bgcolor-yes ttm-bg ttm-right-span ttm-bgcolor-grey mt_60 pt-60 mb_60 pb-60 res-991-mt-0 res-991-pt-0">
                            <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>

<aside class="widget widget-recent-post with-title" style={{textAlign:'left'}}>
    <h3 class="widget-title">Berita</h3>
    <ul class="widget-post ttm-recent-post-list">
                        <li>
                    <span class="post-date">{firstNews?.date}</span>
                    <Link to={`/post/berita/${firstNews?.id}`}> {firstNews?.title}</Link>
                </li>
            </ul>
</aside>
              </div>
                    </div>
                 
                </div>
            </div>
           

        </div>
        </div>
    );
};

export default Download;