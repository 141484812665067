import React from 'react';
import { Link } from 'react-router-dom';


const Header = () => {
  return (
    <header id="masthead" class="header ttm-header-style-01">
            
    <div class="top_bar ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 d-flex flex-row align-items-center">
                    <div class="top_bar_contact_item">
                        <div class="top_bar_icon"><i class="fa fa-phone"></i></div>0274 485705 
WA: 081234577644                    </div>
                    <div class="top_bar_contact_item ml-auto">
                        <a href="https://upnyk.ac.id/">Portal UPN</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="site-header-menu" class="site-header-menu ttm-bgcolor-white">
        <div class="site-header-menu-inner ttm-stickable-header">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="site-navigation d-flex flex-row">
                            <div class="site-branding mr-auto">
                                <a class="home-link" href="/" rel="home">
                                    <img id="logo-img" class="img-center Page-header-upn-logo" src={require('../images/logo.png')} alt="logo-img"/>
                                    <img id="logo-img" class="img-center Page-header-upn-blu" src={require('../images/blu.png')} alt="logo-img"/>
                                    <span class="logo-head">
                                        <div class="logo-head_2"> BIRO UMUM DAN KEUANGAN</div>
                                        <div class="logo-head_1"> UPN "VETERAN" Yogyakarta</div>
                                    </span>
                                </a>
                            </div>
                            <div class="btn-show-menu-mobile menubar menubar--squeeze">
                                <span class="menubar-box">
                                    <span class="menubar-inner"></span>
                                </span>
                            </div>
                            <nav class="main-menu menu-mobile" id="menu">
                                <ul class="menu">
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <a href="/profil">Profil</a></li>                                   
                                    <li>
                                        <a href="/download">Download</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
  );
};

export default Header;