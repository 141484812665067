import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';


const Post = () => {
  const { dir } = useParams();
  const { id } = useParams();
  const [content, setContent] = useState('');
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(`/posts/${dir}/${id}.md`);
        if (response.ok) {
          const text = await response.text();
          setContent(text);
        } else {
          setContent('Post not found');
        }
      } catch (error) {
        setContent('Error loading post');
      }
    };

    fetchContent();
  }, [id]);

  useEffect(() => {
    const fetchPosts = async () => { 
      const response = await fetch(`/posts/${dir}/posts.json`);
      const postList = await response.json();
      
      setRelatedPosts(postList);
    };

    fetchPosts();

    
  }, []);

  

  return (

    <div>
    <div class="ttm-page-title-row">
    <div class="ttm-page-title-row-inner  ttm-bgcolor-darkgrey">
        <div class="container" >
            <div class="row align-items-center">
                <div class="col-lg-12" style={{textAlign:'left'}}>
                    <div class="page-title-heading">
                        <h2 class="title">{dir}</h2>
                    </div>
                    <div class="breadcrumb-wrapper">
                        <span><a class="pathway" href="/">Home</a></span><span><a class="pathway" href="#"> {dir}</a></span>                              
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="site-main">
   
    <div class="ttm-row sidebar ttm-sidebar-right ttm-bgcolor-white clearfix">
        <div class="container">
           
            <div class="row">
                <div class="col-xl-9 col-lg-8 content-area" style={{textAlign:'left'}}>
                  
                    <article class="post ttm-blog-single clearfix">

<div class="ttm-blog-single-content">
  <div className="post-content">
      <ReactMarkdown 
          children={content} 
          rehypePlugins={[rehypeRaw]} 
        />
      </div>
</div>

</article>                          
                </div>
                <div class="col-xl-3 col-lg-4 widget-area sidebar-right ttm-col-bgcolor-yes ttm-bg ttm-right-span ttm-bgcolor-grey mt_60 pt-60 mb_60 pb-60 res-991-mt-0 res-991-pt-0">
                    <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>

<aside class="widget widget-recent-post with-title" style={{textAlign:'left'}}>
  <h3 class="widget-title">{dir.charAt(0).toUpperCase() + dir.slice(1)} Terkait</h3>
  <ul class="widget-post ttm-recent-post-list">
  {relatedPosts.slice(0,5).map( item => (
      
      <li>
      <span class="post-date">{item.date}</span>
      <Link to={`/post/pengumuman/${item.id}`}> {item.title}</Link>
  </li>
  ))}
        
    </ul>
</aside>
      </div>
            </div>
         
        </div>
    </div>
   

</div>
</div>






  );
};

export default Post;
