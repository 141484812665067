import React from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer class="footer ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
    <div class="second-footer">
<div class="container">
<div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-8 widget-area">
        <div class="widget widget_text clearfix"> 
            <h5 class="mb-1">BIRO UMUM DAN KEUANGAN</h5>
            <h5>UPN "VETERAN" Yogyakarta</h5>
            <div class="textwidget widget-text">
                <ul class="contact-widget-wrapper">
                    <li>
                        <i class="fa fa-map-marker"></i>
                        Jl. Padjajaran Condong Catur, Yogyakarta. 55283                            </li>
                    <li>
                        <i class="fa fa-phone"></i>0274 485705 
WA: 081234577644                            </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
        <div class="widget widget_nav_menu clearfix">
            <h3 class="widget-title">LINK TERKAIT</h3>
            <ul id="menu-footer-quick-links">
                                    </ul>
        </div>
    </div>
   
</div>
</div>
</div>
<div class="bottom-footer-text">
<div class="container">
<div class="row">
    <div class="col-lg-7">
        <div class="bottom-lt-side-footer">
            <div class="pb-10">© 2023 </div>
        </div>
    </div>
</div>
</div>
</div>        </footer>
  );
};

export default Footer;