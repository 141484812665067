import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Link, Switch } from 'react-router-dom';

const List = () => {
    const { dir } = useParams();
    const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => { 
      const response = await fetch(`/posts/${dir}/posts.json`);
      const postList = await response.json();
      /*const postList = [
        { id: 'post1', title: 'My First Blog Post' },
        { id: 'post2', title: 'My Second Blog Post' },
        { id: 'post3', title: 'My Third Blog Post' }
      ];*/
      setPosts(postList);
    };

    fetchPosts();

    
  }, []);

    return (
        <div>
            <div class="ttm-page-title-row">
                <div class="ttm-page-title-row-inner  ttm-bgcolor-darkgrey">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12" style={{textAlign:'left'}}>
                                <div class="page-title-heading">
                                    <h2 class="title">Semua {dir}</h2>
                                </div>
                                <div class="breadcrumb-wrapper">
                                    <span><a class="pathway" href="/">Home</a></span><span><a class="pathway" href="#"> {dir}</a></span>                                                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="site-main">
                <div class="ttm-row grid-section clearfix">
                    <div class="container">
                    
                        <div class="row">
                            {posts.map( item => (
                            <div class="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-6">
                                <div class="featured-imagebox featured-imagebox-post style1">
                                    <div class="ttm-post-thumbnail featured-thumbnail all-post">
                                        <img class="img-fluid" src="/assets/post/default-image.png" alt="default"/>
                                    </div>
                                    <div class="featured-content featured-content-post ">
                                        <div class="post-meta">
                                            <span class="ttm-meta-line byline"><i class="ti ti-user"></i>Sifulan</span>
                                            <span class="ttm-meta-line cat-link"><i class="ti ti-calendar"></i>{item.date}</span>
                                        </div>
                                        <div class="post-title featured-title">
                                            <h5><Link to={`/post/${dir}/${item.id}`}>{item.title}</Link></h5>
                                        </div>
                                    </div>
                                </div>   
                            </div>                    
                            ))}
                                                
                        </div>
                
                    </div>
                </div>
            </div>
        </div>
    );
};

export default List;